@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input {
  outline: none !important;
}

.users-info {
  width: 100%;
  height: 85%;
  background: linear-gradient(
    0deg,
    #249aff30 0%,
    #35a2ff30 9%,
    #71c0ff30 40%,
    #9dd6ff30 67%,
    #b8e4ff30 88%,
    #c3e9ff30 100%
  );
}

.game-info {
  width: 100%;
  height: 85%;
  background: linear-gradient(
    0deg,
    #f15a2230 0%,
    #f2632230 3%,
    #f2672730 6%,
    #f5925330 27%,
    #f7b47630 48%,
    #f8cc8f30 68%,
    #f9da9e30 86%,
    #fae0a430 100%
  );
}

.login {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    0deg,
    #4f8b3c30 0%,
    #6ea25830 18%,
    #92bc7830 42%,
    #accf8f30 64%,
    #bcda9d30 84%,
    #c2dfa230 100%
  );
}

.contest-info {
  width: 100%;
  height: 85%;
  background: linear-gradient(
    0deg,
    #4f8b3c30 0%,
    #6ea25830 18%,
    #92bc7830 42%,
    #accf8f30 64%,
    #bcda9d30 84%,
    #c2dfa230 100%
  );
}
